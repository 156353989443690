<template>
    <div>
      <div class="card p-4">
          <div class="row p-2 card-title">
              <div class="col">
                  <h6>
                      {{ facility.name }} Settings
                  </h6>
              </div>
          </div>

          <div class="row">
              <div class="col">
                  <label>Facility ID</label>
                  <fg-input class="no-border"
                            type="text"
                            :maxlength="3"
                            :disabled="!is('super')"
                            v-model="facility.id">
                  </fg-input>
              </div>
              <div class="col">
                  <fg-input class="no-border"
                            type="text"
                            label="Name"
                            :disabled="!is('super')"
                            v-model="facility.name">
                  </fg-input>
              </div>
              <div class="col">
                  <manage-bid-year v-if="facility.id"
                                   :facility="facility"
                                   :is-super="is('super')"
                                   @facility="facility = $event" />
              </div>
          </div>
          <hr>
          <div class="row">
              <div class="col">
                  <h6>Configure Bidding Start & End Times</h6>
                  <i>
                      Enter times in LOCAL time ({{ facilityTimezone }}). Times will remain the same regardless of
                      Daylight Saving Time.
                  </i>
                  <table class="table results mt-2">
                      <thead>
                      <tr>
                          <th style="width:50%">Days of Week</th>
                          <th>Bid Start Time</th>
                          <th>Bid End Time</th>
                          <th v-if="facility.bid_days && facility.bid_days.length > 1" style="width: 25px"></th>
                      </tr>
                      </thead>
                      <tr v-for="(day, i) in bidHours" :key="i">
                          <td>
                              <multiselect class="w-100 mx-auto"
                                           :multiple="true"
                                           v-model="day.days"
                                           tag-placeholder=""
                                           placeholder="Select Days of Week"
                                           :options="filteredDays.map(day => day.id)"
                                           :custom-label="opt => days.find(x => x.id === opt).name"
                                           label="name"
                                           :allow-empty="false"
                                           :close-on-select="false"
                                           :hide-selected="true"
                                           select-label=""
                                           deselect-label=""/>
                          </td>
                          <td>
                              <div class="w-75 d-inline-block mr-2">
                                  <fg-input class="no-border">
                                      <el-time-select v-model="day.bid_start"
                                                      placeholder="Bid Start Time"
                                                      :picker-options="{
                                                  step: '00:15',
                                                  start: '00:00',
                                                  end: '23:59',
                                              }"></el-time-select>
                                  </fg-input>
                              </div>
                              <b>{{ facilityTimezone }}</b>
                          </td>
                          <td>
                              <div class="w-75 d-inline-block mr-2">
                                  <fg-input class="no-border">
                                      <el-time-select v-model="day.bid_end"
                                                      placeholder="Bid End Time"
                                                      :picker-options="{
                                          start: '00:00',
                                          end: '23:59',
                                          step: '00:15'
                                      }"></el-time-select>

                                  </fg-input>
                              </div>
                              <b>{{ facilityTimezone }}</b>
                          </td>
                          <td v-if="facility.bid_days && facility.bid_days.length > 1">
                              <i class="fas fa-trash fa-lg pt-2 text-danger cursor-pointer"
                                 @click="facility.bid_days.splice(i, 1)"></i>
                          </td>
                      </tr>
                  </table>
                  <span v-if="selectedDays.length < 7"
                        class="add-details mb-2"
                        @click="facility.bid_days.push({ days: [], bid_start: '', bid_end: '' })">
                      <i class="fas fa-plus"></i> New Days
                  </span>
              </div>
          </div>
          <div class="row">
              <div class="col-6 col-md-4">
                  <label>Facility Timezone</label>
                  <multiselect class="w-100 mx-auto"
                               v-model="facility.timezone"
                               tag-placeholder=""
                               placeholder="Select Timezone"
                               :options="timezones"
                               :allow-empty="false"
                               select-label=""
                               deselect-label=""/>
<!--                    <el-select :value="facility.timezone" class="w-100 select-primary">-->
<!--                        <el-option v-for="(zone, i) in timezones" class="select-primary"-->
<!--                                   :value="zone"-->
<!--                                   :label="zone"-->
<!--                                   :key="i">-->
<!--                        </el-option>-->
<!--                    </el-select>-->
              </div>
              <div class="col-6 col-md-4">
                <label class="d-block">
                  Auto Open/Close Bidding
                  <el-tooltip>
                    <div class="text-left" slot="content">
                      Enabling this feature will prevent BUEs from entering bids for themselves (if enabled) while
                      bidding is "closed" for the facility.
                    </div>
                    <i class="fas fa-question-circle"></i>
                  </el-tooltip>
                </label>
                <n-switch v-model="facility.auto_open_close"
                          on-text="ON"
                          :on-value="1"
                          :off-value="0"
                          color="bg-lightblue"
                          class="align-top ml-2"></n-switch>
              </div>
          </div>
          <hr>
          <div class="row">
              <div class="col">
                  <label class="d-block">
                      Enable Test Mode
                      <el-tooltip>
                          <div class="text-left" slot="content">
                              Mode for testing the site and different configurations. In test mode...
                              <ul class="m-0">
                                  <li>Notifications will not be sent to controllers, notifications that would be sent
                                      can be viewed in the Message screen</li>
                              </ul>
                          </div>
                          <i class="fas fa-question-circle"></i>
                      </el-tooltip>
                  </label>
                  <n-switch v-model="facility.test_mode"
                            on-text="ON"
                            :on-value="1"
                            :off-value="0"
                            color="bg-lightblue"
                            class="align-top ml-2"></n-switch>
              </div>
              <div class="col">
                  <fg-input class="no-border"
                            type="text"
                            label="Facility Slack Webhook"
                            disabled
                            v-model="facility.test_slack">
                  </fg-input>
              </div>
          </div>
          <hr>
          <div class="row">
              <div class="col">
                  <label class="d-block">
                      Allow Text Messaging
                      <el-tooltip>
                          <div class="text-left" slot="content">
                              Allows users for this facility to select text messaging as a notification option. Facility will
                              be expected to cover costs associated with any text messages sent.
                          </div>
                          <i class="fas fa-question-circle"></i>
                      </el-tooltip>
                  </label>
                  <n-switch v-model="facility.allow_text"
                            on-text="ON"
                            :on-value="1"
                            :off-value="0"
                            color="bg-lightblue"
                            class="align-top ml-2"></n-switch>
              </div>
              <div class="col">
                  <label class="d-block">
                      Enable Privacy
                      <el-tooltip>
                          <div class="text-left" slot="content">
                              Require users to be logged in to view lines and leave slots.
                          </div>
                          <i class="fas fa-question-circle"></i>
                      </el-tooltip>
                  </label>
                  <n-switch v-model="facility.hide_all_content"
                            on-text="ON"
                            :on-value="1"
                            :off-value="0"
                            color="bg-lightblue"
                            class="align-top ml-2"></n-switch>
              </div>
              <div class="col">
                  <fg-input class="no-border"
                            type="number"
                            label="Bid Lock Time"
                            :maxlength="3"
                            v-model="facility.bid_lock_time">
                  </fg-input>
              </div>
          </div>
          <div class="row">
              <div class="col text-center">
                  <n-button round type="success" @click.native="saveFacility()">Save Facility Configuration</n-button>
              </div>
          </div>
      </div>
    </div>
</template>
<script>
import { RepositoryFactory } from '@/repositories/RepositoryFactory'
import moment from 'moment'
import { mapGetters, mapState, mapMutations } from 'vuex'
import { Switch } from '@/components/stateless'
import { TimeSelect, Dialog } from 'element-ui'
import Multiselect from 'vue-multiselect'
import ManageBidYear from '@/components/ManageBidYear'
const FacilityRepository = RepositoryFactory.get('facilities')

export default {
  name: 'facility-admin',
  components: {
    ManageBidYear,
    [Switch.name]: Switch,
    [TimeSelect.name]: TimeSelect,
    [Dialog.name]: Dialog,
    Multiselect
  },
  data () {
    return {
      disabled: false,
      facility: {},
      bidHours: [],
      days: [
        { id: 0, name: 'Sunday' },
        { id: 1, name: 'Monday' },
        { id: 2, name: 'Tuesday' },
        { id: 3, name: 'Wednesday' },
        { id: 4, name: 'Thursday' },
        { id: 5, name: 'Friday' },
        { id: 6, name: 'Saturday' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      is: 'auth/is',
      allowText: 'facility/allowText'
    }),
    ...mapState('facility', ['content']),
    filteredDays () {
      return _.difference(this.days, this.selectedDays)
    },
    /** Find days that already have times */
    selectedDays () {
      return _.flatMap(this.bidHours, d => _.map(d.days, day => this.days[day]))
    },
    // canChangeBidYear() {
    //       return this.facility.bid_year === parseInt(moment().format('Y'));
    // },
    timezones () {
      return moment.tz.names()
    },
    facilityTimezone () {
      return moment.tz(this.facility.timezone).zoneAbbr()
    }
    // timezone() {
    //     return window.moment.tz.zone(window.moment.tz.guess()).abbr(window.moment());
    // },
  },
  methods: {
    ...mapMutations(['request']),

    /** Save changes to facility */
    saveFacility () {
      const payload = JSON.parse(JSON.stringify(this.facility))
      // Ensure facility is lowercase
      payload.id = payload.id.toLowerCase()

      // Convert times to UTC
      payload.bid_days = this.utcTimes(this.bidHours, this.facility.timezone, ['bid_start', 'bid_end'])

      // Save the facility
      FacilityRepository.update(this.facility.id, payload)
        .then(r => {
          // Update teh facility state
          this.$store.commit('facility/setFacility', r.data)
          this.setFacility(r.data)
        })
    },
    setFacility (facility) {
      // Clone the facility from vuex
      this.facility = JSON.parse(JSON.stringify(facility))

      // Guess the facility timezone if it's not already set
      this.facility.timezone = this.facility.timezone || moment.tz.guess()

      // Convert bid hours from JSON
      this.bidHours = JSON.parse(JSON.stringify(this.utcTimes(this.facility.bid_days, this.facility.timezone, ['bid_start', 'bid_end'], true)))
    }
  },
  created () {},
  mounted () {
    // Clone the facility to prevent live edits
    this.setFacility(this.$store.state.facility.facility)

    // Seed bid days if not set
    if (!this.facility.bid_days) {
      this.facility.bid_days = [
        { days: [], bid_start: '', bid_end: '' }
      ]
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
